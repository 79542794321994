import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Container, CircularProgress, Stack ,Grid} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyOTP } from '../../services/auth';
import VoiceTypingComponent from '../voiceTyping/VoiceTypingComponent ';

const LoginForm = ({ onClose, onLogin }) => {
	const [formData, setFormData] = useState({
	  userName: '',
	  password: '',
	  
	});
	const navigate = useNavigate();
	 
	const [loading, setLoading] = useState(false);
  
	const handleChange = (event) => {
	  setFormData({
		...formData,
		[event.target.name]: event.target.value,
	  });
	};
  
	const handleForgotPasswordClick = () => {
	  navigate('/forget-password');
	};
  
	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
		  const response = await onLogin(formData.userName, formData.password);
		} catch (error) {
		  console.error('Error:', error);
		} finally {
		  setLoading(false);
		}
	  };
	  
	  // Prevent navigation while form submission is in progress
	  useEffect(() => {
		const handleBeforeUnload = (event) => {
		  if (loading) {
			event.preventDefault();
			event.returnValue = ''; // Some browsers may need this
		  }
		};
	  
		window.addEventListener('beforeunload', handleBeforeUnload);
	  
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [loading]);
	  
	
	

	return (
		<Container
			component='main'
			// maxWidth='sm'
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'fixed',
				backgroundColor: '#D9D9D9',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
			}}>
			<Box
				gap={0}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#FFFCF5',
					color: '#87BCDE',
					padding: '35px',
					borderRadius: '5px',
					margin: '10px',
					height: 'auto',
					overflow: 'hidden',
				}}>
				<Stack gap={0}
					alignItems={'center'}
					justifyContent={'center'}
					marginBottom={0}
        		>
					<Typography
						variant='h6'
						color='primary.main'
						sx={{ fontSize: '40px' }}>
						Welcome
					</Typography>
					<Typography
						component='p'
						variant='h6'
						color='se.main'
						sx={{ fontSize: '16px', display: 'flex', textAlign: 'center' }}>
						Log in to your account and see what's new
					</Typography>
				</Stack>

				<Stack
					gap={4}
					justifyContent={'center'}>
					<form onSubmit={handleSubmit}>
						<TextField
							type='text'
							label='User Name'
							name='userName'
							fullWidth
							margin='normal'
							value={formData.userName}
							onChange={handleChange}
							required
						/>
						<TextField
							type='password'
							label='Password'
							name='password'
							fullWidth
							margin='normal'
							value={formData.password}
							onChange={handleChange}
							required
						/>
					 
            			<Grid
							container
							justifyContent={{ xs: 'center', md: 'flex-end' }}>
							<Grid item>
								<Link
									variant='body2'
									sx={{
										color: 'primary.main',
										textDecoration: 'none',
										cursor: 'pointer',
									}}
									onClick={handleForgotPasswordClick}>
									Forgot password?
								</Link>
							</Grid>
						</Grid>
						<Button
							type='submit'
							variant='contained'
							className='button-style'
							disabled={loading}
							sx={{
								width: '206px',
								display: 'flex',
								marginLeft: 'auto',
								marginRight: 'auto',
								fontSize: {
									xs: '12px',
									sm: '14px',
								},
								borderRadius: '5px'
							}}>
							{loading ? (
								<CircularProgress
									color='inherit'
								/>
							) : (
								'Log In'
								 
							)}

						</Button>
						<VoiceTypingComponent />

						{/* <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mr: 2 }}>
          Log In
        </Button> */}
						{/* <Button onClick={onClose} variant="outlined" color="primary" sx={{ mt: 3 }}>
          Cancel
        </Button> */}
					</form>
				</Stack>
				<Typography
					variant='body2'
					sx={{ mt: 2 }}>
					Don't have an account?{' '}
					<Link
						href='#'
						color='primary'>
						Sign Up
					</Link>
				</Typography>
        <Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '16px',
							flexDirection: 'column',
						}}>
						
						<br />
						<Typography
							component='p'
							variant='h5'
							sx={{ fontSize: '12px', color: '#203C7E', textAlign: 'center' }}>
							By Signing In You Agree To Our{' '}
							<span style={{ color: '#203C7E' }}>Terms of Service</span> And
							Consent To Our{' '}
							<span style={{ color: '#203C7E' }}>Privacy Policy</span>
						</Typography>
					</Box>
			</Box>
      
		</Container>
	);
};

export default LoginForm;
